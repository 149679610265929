@import "./utils/mixins";
@import "./utils/variables";

.App {
  text-align: center;
  border: none;

}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}


.form-info {
  background: #ed480014;
  border: 1px solid #ed4800ad;
  border-radius: 8px;
  color: #ed4800d4;
  font-size: 14px;
  line-height: 18px;
  margin: 0 2px 8px;
  padding: 8px;
}

$color-header-title: rgb(255, 255, 255);
$color-popuplogin-text: rgb(102, 180, 247);
$bg-color-popuplogin-content: rgb(71, 94, 127);
$border-color-popuplogin-content: rgb(117, 128, 144);

$small-bg-image: url("./assets/images/frontendbackground-small.png");
$medium-bg-image: url("./assets/images/frontendbackground-medium.png");
$large-bg-image: url("./assets/images/frontendbackground-large.png");
$xlarge-bg-image: url("./assets/images/frontendbackground-huge.png");

.password-form {
  width: 100%;
  height: 100vh;
  background: linear-gradient(90deg,#1e1a22,#27242b);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .header-subtitle {
    color: #727272;
    font-size: 20px;
  }

  .password-form-inner {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .password-form-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0px 32px;

      .header-content-wrapper {
        .header-title {
          color: #e0e0e0;
          font-size: 24px;
          padding: 16px 0px;
        }
      }

      .header-logo-wrapper {
        padding: 14px 0px;
      }
    }

    .password-form-content {
      position: relative;
      flex: 1;
      padding: 32px;

      .password-form-content-main {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;

        height: 100%;

        .password-form-text {
          font-size: 22px;
          line-height: 32px;
          color: #e0e0e0;
          a {
            color: #ed4800;
            text-decoration: none;
          }

        }

        .input-group {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;

          &.last-input-group {
            padding-bottom: 0vw;
          }

          .input-label {
            text-align: right;
            padding-right: 1.25vw;
            color: #c0c0c0;
          }
        }

        .error-wrapper {
          position: relative;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          color: $meta-orange;
          font-size: 14px;
          padding-left: 8px;
          padding-right: 8px;


          &.error-birthdate {
            position: absolute;
            top: 0;
            left: 0;
            padding-left: 0;
            width: 12vw;
          }

          .password-requirements-wrapper {
            font-size: 16px;
            width: 100%;

            .req-list {
              margin-top: 8px;
              margin-left: 8px;
              font-size: 14px;
            }

            .req-item {
              line-height: 22px;
            }
          }
        }

      }
    }
  }

  .password-form-footer-inner {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    .password-form-footer-buttons {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      width: 100%;

      .button-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border-radius: 12px;
        background: #221f26;
        box-shadow: 0 1px 8px 0 #0b0a0a40;
        padding: 8px 32px;
        height: 40px;
        transition: 200ms linear filter, 200ms linear transform;

        button {
          text-shadow: 0 0 8px #33e288;
          color: #33e288;
          font-size: 18px;
          border: none;
          cursor: pointer;
          background: transparent;
        }

        &:hover {
          filter: brightness(1.2);
        }

        .footer-button {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .footer-button-large {
          height: calc-viewpoint-size(75);
        }

        .background-lg {
          position: absolute;
          height: calc-viewpoint-size(75);
          z-index: -1;
        }

        .bg-image {
          width: 100%;
          height: 100%;
        }

        .button-text-lg {
          font-size: 22px;
          color: $white;
        }
      }
    }
  }
}

// xs

.popup-block {
  max-width: 100%;
}

.header-title {
  font-size: 18px;
}


// sm
@media only screen and (min-width: 900px) {
  .popup-block {
    max-width: 800px;
    min-width: 600px;
  }

  .header-title {
    font-size: 24px;
  }

}


// md
@media only screen and (min-width: 1200px) {

}