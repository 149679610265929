$meta-orange: rgba(243, 96, 45, 1);
$meta-powderblue: rgba(144, 195, 255, 1);
$meta-green: rgba(196, 232, 118, 1);
$white: rgba(255, 255, 255, 1);
$black: rgba(0, 0, 0, 1);
$spacing-smaller: 0.6vw;
$spacing-small: 1.25vw;
$spacing-large: 2.5vw;
$transparent-border: rgba(255, 255, 255, 0.6);
$transparent: rgba(255, 255, 255, 0);
$select-background: rgba(0, 0, 0, 0.2);
$notch-corner-height: 8px;

$main-window-background-color: rgba(19, 44, 72, 0.4);
$main-window-background-color-dark: rgba(19, 44, 72, 0.7);
$main-window-background-color-darker: rgba(19, 44, 72, 0.9);
$main-window-stroke-color: rgba(122, 148, 174, 0.7);
$main-window-stroke-color-shadowed: rgba(122, 148, 174, 0.1);
$main-window-stroke-color-full: rgba(122, 148, 174, 1);
$main-window-stroke-color-none: rgba(122, 148, 174, 0);
$main-window-padding: 0.5vw;

$popup-window-bg-color: rgb(64, 79, 102);
$popup-window-bg-color-darker: rgb(52, 67, 90);
$popup-content-box-bg-color: rgb(72, 95, 127);
$popup-content-box-border-color: rgb(106, 124, 145);

$home-progress-default-color: rgba(133, 162, 192, 1);
$home-progress-available-color: rgba(148, 192, 255, 1);
$home-progress-active-color: rgba(186, 227, 100, 1);
$home-progress-active-darker-color: rgba(155, 186, 89, 1);
$home-progress-goal-color: rgba(254, 198, 24, 1);

$stat-text-color: rgba(164, 206, 255, 1);
$stat-main-background: rgba(84, 124, 159, 0.6);
$stat-bottom-background: rgba(19, 44, 72, 0.18);
$stat-button-border: rgba(187, 200, 212, 1);
$stat-button-background-inactive: rgba(243, 96, 45, 0.35);
$stat-button-border-inactive: rgba(187, 200, 212, 0.35);
$stat-box-background: rgba(79, 112, 158, 0.7);
$stat-graph-container-background: rgba(93, 121, 149, 0.7);
$stat-target-path-background: rgba(33, 68, 115, 1);

$profile-image-border: rgba(46, 46, 46, 1);

$graph-background: rgba(46, 79, 125, 0.7);
$graph-content-background: rgba(57, 89, 130, 0.7);

$checkbox-hover-color: rgba(148, 192, 255, 0.5);
$checkbox-active-color: rgba(148, 192, 255, 1);

$achievement-border: rgba(98, 98, 98, 1);
$comment-background: rgba(193, 193, 193, 0.52);
$comment-border-color: rgba(255, 255, 255, 0.52);
$comment-bottom-height: 4px;

$scroll-bar-border-color: rgba(155, 168, 185, 1);

// font sizes for FullHD screens(scalable for other 16:9 aspect ratio
$font-size-xs: 0.625vw; // 12px
$font-size-sm: 0.9375vw; // 18px
$font-size-md: 1.25vw; // 24px
$font-size-lg: 1.5625vw; // 30px
$font-size-xl: 1.875vw; // 36px
$font-size-xxl: 2.1875vw; // 40px

// popup window sizes
$popup-width-xs: 28.75vw; // 552px
$popup-width-sm: 35.15625vw; // 675px
$popup-width-md: 39.84375vw; // 765px
$popup-width-lg: 41.40625vw; // 795px
$popup-width-xl: 51.09375vw; // 981px
$popup-width-xxl: 62.03125vw; // 1191px

$popup-height-xs: 21.09375vw; // 405px
$popup-height-sm: 23.125vw; // 444px
$popup-height-md: 29.6875vw; // 570px
$popup-height-lg: 30.78125vw; // 591px
$popup-height-xl: 34.6875vw; // 666px
$popup-height-xxl: 43.90625vw; // 843px
$popup-height-xxxl: 45.625vw; // 876px

$popup-footer-height: 7.34375vw; // 141px

// navmenu dropdown sizes
$dropdown-item-width-xxxs: 4.375vw; // 84px
$dropdown-item-width-xxs: 6.09375vw; // 117px
$dropdown-item-width-xs: 10.78125vw; // 207px
$dropdown-item-width-sm: 12.5vw; // 240px
$dropdown-item-width-md: 13.125vw; // 252px
$dropdown-item-width-lg: 14.0625vw; // 270px
$dropdown-item-width-xl: 15.46875vw; // 297px
$dropdown-item-width-xxl: 15.9375vw; // 306px
$dropdown-item-width-xxxl: 18.28125vw; // 351px
$dropdown-item-width-options: 15.15625vw; // 291px

$dropdown-item-height-md: 2.5vw;
$dropdown-item-height-options: 2.03125vw;

$dropdown-handle-width: 2.5vw;
$dropdown-handle-height: 2.5vw;
$dropdown-handle-width-options: 2.03125vw;
$dropdown-handle-height-options: 2.03125vw;
