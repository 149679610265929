// @font-face {
//   font-family: "TheMeta";
//   src: url("./assets/fonts/Metropolis-Regular.ttf");
//   font-style: normal;
//   font-weight: normal;
// }

// @font-face {
//   font-family: "TheMeta";
//   src: url("./assets/fonts/Metropolis-SemiBold.ttf");
//   font-style: normal;
//   font-weight: bold;
// }

// @font-face {
//   font-family: "TheMeta";
//   src: url("./assets/fonts/Metropolis-RegularItalic.ttf");
//   font-style: italic;
//   font-weight: normal;
// }

// @font-face {
//   font-family: "TheMeta";
//   src: url("./assets/fonts/Metropolis-SemiBoldItalic.ttf");
//   font-weight: bold;
//   font-style: italic;
// }

* {
  /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
  font-family: 'Roboto';
  box-sizing: border-box;
}

html,
body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  background-color: transparent;
  padding: 0;
  margin: 0;
  font-size: 100%;
}

#root {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  flex: 1; // root is the child of a flex container so fill it
}

body {
  margin: 0;
  font-family: 'Robot', sans-serif;
  // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  //   "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  //   sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
