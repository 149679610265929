@import "../../utils/mixins";
@import "../../utils/variables";

.popup-block {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  height: auto;
  background: #27242b;
  border-radius: 16px;
  padding: 16px;
  box-shadow: 0 8px 32px 0 #0000003b, inset 0 0 8px 0 #0b0b0b14;
  margin: 16px;

  .body {
    position: relative;
    flex: 1;

    .content {
      width: 100%;
      height: 100%;
    }
  }

  .footer {
    position: relative;

    .content {
      width: 100%;
      height: 100%;
    }

    .background {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;

      .top {
        width: 100%;
        height: calc-viewpoint-size(105);
      }

      .bottom {
        position: relative;
        display: flex;
      }
    }
  }
}
