.styled-input {
  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  background: #19191cc2;
  border-radius: 8px;
  height: 40px;
  box-shadow: inset 0 0 16px 0 #0000002e;

  user-select: text;

  &:focus {
    outline: none;
  }
}


.input-label {
  align-items: center;
  white-space: nowrap;
  flex: 1;
  font-size: 18px;
}

.input-group {
  margin-bottom: 16px;
}


.input-wrapper {
  width: 100%;
  input {
    width: 100%;
    padding: 0px 8px;
    font-size: 18px;
    color: #e0e0e0;
  }
}