@import "./variables";

@mixin for-desktop-up {
  @media (min-width: 1279px) {
    @content;
  }
}
@mixin for-big-desktop-up {
  @media (min-width: 1899px) {
    @content;
  }
}
@mixin for-verybig-desktop-up {
  @media (min-width: 2639px) {
    @content;
  }
}
@mixin for-huge-desktop-up {
  @media (min-width: 3299px) {
    @content;
  }
}

@mixin _responsive-bg-image(
  $small-image,
  $small-width,
  $small-height,
  $medium-image,
  $medium-width,
  $medium-height,
  $large-image,
  $large-width,
  $large-height,
  $xlarge-image,
  $xlarge-width,
  $xlarge-height
) {
  background-repeat: no-repeat;
  background-image: $small-image;
  min-width: $small-width;
  min-height: $small-height;

  @include for-desktop-up {
    background-image: $small-image;
    min-width: $small-width;
    min-height: $small-height;
  }
  @include for-big-desktop-up {
    background-image: $medium-image;
    min-width: $medium-width;
    min-height: $medium-height;
  }
  @include for-verybig-desktop-up {
    background-image: $large-image;
    min-width: $large-width;
    min-height: $large-height;
  }
  @include for-huge-desktop-up {
    background-image: $xlarge-image;
    min-width: $xlarge-width;
    min-height: $xlarge-height;
  }
}

@function calc-viewpoint-size($pixel) {
  @return ($pixel / 1920 * 100) + vw;
}

@function calc-viewpoint-size-h($pixel) {
  @return ($pixel / 1080 * 100) + vh;
}


@function calc-viewpoint-font-size($pixel) {
  @return ($pixel / 1920 * 100 / 2 * 3) + vw;
}
