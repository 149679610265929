@import "../../utils/mixins";
@import "../../utils/variables";

.popup-content-box-container {
  position: relative;

  max-width: 100%;
  max-height: 100%;

  .background-container {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: -1;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .bg-top {
      width: 100%;
      height: calc-viewpoint-size(12);

      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-content: flex-start;

      .bg-top-corner {
        width: calc-viewpoint-size(12);
        height: calc-viewpoint-size(13.5);
      }

      .bg-top-rest {
        flex: 1;
        border-top-style: solid;
        border-top-width: 1px;
        border-top-color: $popup-content-box-border-color;
        border-right-style: solid;
        border-right-width: 1px;
        border-right-color: $popup-content-box-border-color;

        background-color: $popup-content-box-bg-color;
      }
    }

    .bg-bottom {
      flex: 1;

      width: 100%;

      border-left-style: solid;
      border-left-width: 1px;
      border-left-color: $popup-content-box-border-color;
      border-right-style: solid;
      border-right-width: 1px;
      border-right-color: $popup-content-box-border-color;
      border-bottom-style: solid;
      border-bottom-width: 1px;
      border-bottom-color: $popup-content-box-border-color;

      background-color: $popup-content-box-bg-color;
    }
  }

  .content-container {
    width: 100%;
    max-height: 100%;
  }
}
